import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { RichText } from 'prismic-reactjs'

import {
  Box,
  DisplayText,
  FlexCol,
  FlexGrid,
  H2,
  H3,
  Highlight,
  SEO,
} from 'src/components'

import serializer from 'src/utils/serializer'
import linkResolver from 'src/utils/linkResolver'

const OurImpact = () => (
  <StaticQuery
    query={graphql`
      query {
        impact: prismicImpactPage {
          dataString
          data {
            areas {
              area_image_1 {
                fluid(maxWidth: 960) {
                  ...GatsbyPrismicImageFluid
                }
              }
              area_image_2 {
                fluid(maxWidth: 960) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
            before_and_after_group {
              before_and_after_image_1 {
                fluid(maxWidth: 960) {
                  ...GatsbyPrismicImageFluid
                }
              }
              before_and_after_image_2 {
                fluid(maxWidth: 960) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
        education01: file(relativePath: { eq: "our-impact/education01.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      let page = JSON.parse(data.impact.dataString)
      return (
        <>
          <SEO
            title={
              page.opengraph_title ? RichText.asText(page.opengraph_title) : ''
            }
            description={
              page.opengraph_description
                ? RichText.asText(page.opengraph_description)
                : ''
            }
            image={page.opengraph_image ? page.opengraph_image.url : ''}
          />
          <Box px={[3, null, null, 4]} py={4} bg="bg.default">
            <Box maxWidth={1280} mx="auto">
              <FlexCol alignItems="center">
                {page.hero_header && (
                  <DisplayText
                    mb="0.5em"
                    textAlign="center"
                    style={{ transform: 'rotate(-7.5deg)' }}
                  >
                    <Highlight variant={2}>
                      {RichText.asText(page.hero_header)}
                    </Highlight>
                  </DisplayText>
                )}
                {page.hero_subheader && (
                  <Box maxWidth={512} mx="auto">
                    <H3
                      children={RichText.asText(page.hero_subheader)}
                      textAlign="center"
                    />
                  </Box>
                )}
              </FlexCol>
            </Box>
          </Box>
          {page.areas && (
            <Box>
              {page.areas.map((area, index) => (
                <Box
                  px={[3, null, null, 4]}
                  py={[4, 5]}
                  bg={index % 2 === 0 ? 'bg.wash' : 'bg.default'}
                >
                  <Box maxWidth={1280} mx="auto">
                    <H2
                      children={RichText.asText(area.area_heading)}
                      mb="0.25em"
                      textAlign="center"
                    />
                    <H3
                      children={RichText.asText(area.area_subheading)}
                      mb="2em"
                      textAlign="center"
                    />
                    <FlexGrid gutterX={[3, null, 4]} gutterY={[2]}>
                      <Box width={[1, null, 1 / 2]}>
                        <Box mb={2}>
                          <Img
                            fluid={
                              data.impact.data.areas[index].area_image_1.fluid
                            }
                          />
                        </Box>
                        <Box>
                          <Img
                            fluid={
                              data.impact.data.areas[index].area_image_2.fluid
                            }
                          />
                        </Box>
                      </Box>
                      <Box width={[1, null, 1 / 2]}>
                        {RichText.render(
                          area.area_body,
                          linkResolver,
                          serializer
                        )}
                      </Box>
                    </FlexGrid>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
          <Box px={[3, null, null, 4]} py={[4, 5]} bg="bg.alt">
            <Box maxWidth={1280} mx="auto">
              {page.before_and_after_header && (
                <H2
                  children={RichText.asText(page.before_and_after_header)}
                  mb="2em"
                  textAlign="center"
                />
              )}
              <FlexGrid gutterY={[4]}>
                {page.before_and_after_group.map((group, index) => (
                  <Box width={1}>
                    <FlexGrid gutterX={[3, null, 4]} gutterY={[2, 3]}>
                      <Box width={[1, null, 1 / 3]}>
                        <Box>
                          {group.before_and_after && (
                            <H3
                              children={RichText.asText(group.before_and_after)}
                              mb="0.5em"
                            />
                          )}
                          {RichText.render(
                            group.before_and_after_body,
                            linkResolver,
                            serializer
                          )}
                        </Box>
                      </Box>
                      <Box order={[-1, null, 0]} width={[1, null, 2 / 3]}>
                        <FlexGrid gutterX={[2]} gutterY={[2]}>
                          <Box width={[1, 1 / 2]}>
                            <Img
                              fluid={
                                data.impact.data.before_and_after_group[index]
                                  .before_and_after_image_1.fluid
                              }
                            />
                          </Box>
                          <Box width={[1, 1 / 2]}>
                            <Img
                              fluid={
                                data.impact.data.before_and_after_group[index]
                                  .before_and_after_image_2.fluid
                              }
                            />
                          </Box>
                        </FlexGrid>
                      </Box>
                    </FlexGrid>
                  </Box>
                ))}
              </FlexGrid>
            </Box>
          </Box>
        </>
      )
    }}
  />
)

export default OurImpact
